
import { Component, Vue } from 'vue-property-decorator';
import CarColorPicker from '@/modules/cars/components/car-color-picker.vue';
import CarCalendar from '@/modules/cars/components/car-calendar.vue';
import CarActions from '@/modules/cars/components/car-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import CarRatesTooltip from '@/modules/cars/components/car-rates-tooltip.vue';
import CarTableV2 from '@/modules/cars/components/car-table-v2.vue';
import ToggleDiff from '@/modules/common/modules/rates/components/toggle-diff.vue';
import CarTogglePrices from '@/modules/cars/components/car-toggle-prices.vue';
import { Inject } from 'inversify-props';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';

@Component({
    components: {
        CarColorPicker,
        CarCalendar,
        CarActions,
        PageWrapper,
        CarHeader,
        CarRatesTooltip,
        CarTableV2,
        ToggleDiff,
        CarTogglePrices,
    },
})
export default class RatesCalendarPage extends Vue {
    @Inject(CarsServiceS)
    readonly carsService!: CarsService;
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;

    mounted() {
        this.eventsManagerService.saveIsLoadEventByPOS(true);
    }

    beforeDestroy() {
        this.eventsManagerService.saveIsLoadEventByPOS(false);
    }
}
